import getUserLocationFromClient from "@/lib/location/getUserLocationFromClient";
import { PRODUCT_TYPES } from "../constants";
import { checkPersonalizationFlag } from "@/utils/functions/checkPersonalizationFlag";

const getFilters = (productType?: string) => {
  const isPersonalizedResults: boolean = checkPersonalizationFlag();

  const userCountry = getUserLocationFromClient();
  const productTypeFilter = productType
    ? `product:"${productType}"`
    : PRODUCT_TYPES.map((type) => `product:"${type}"`).join(" OR ");

  const locationRestrictionFilter = `(blocked_in:null OR NOT blocked_in:"${userCountry}") AND (allowed_in:null OR allowed_in:"${userCountry}")`;

  return `${!isPersonalizedResults ? productTypeFilter : `(${productTypeFilter}) AND ${locationRestrictionFilter}`}`;
};

export default getFilters;
